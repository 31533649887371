@import '../../common.module.scss';

.container {
  height: 300px;
  width: 430px;
  padding: 10px 5px 0 0;
}
.img {
  position: absolute;
  object-fit: cover;
  width: 50%;
  background-color: aqua;
}

.wrapper {
  position: relative;
  border-radius: 20px;
  border: solid 1px $tGrayWhite;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.wrapper1 {
  :global(.img0) {
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.wrapper2 {
  :global(.img0) {
    left: 0;
    height: 100%;
    border-right: solid 2px #fff;
  }
  :global(.img1) {
    border-left: solid 2px #fff;
    left: 50%;
    height: 100%;
  }
}

.wrapper3 {
  :global(.img0) {
    left: 0;
    height: 100%;
    border-right: solid 2px #fff;
  }
  :global(.img1) {
    left: 50%;
    height: 150px;
    border-left: solid 2px #fff;
    border-bottom: solid 2px #fff;
  }
  :global(.img2) {
    left: 50%;
    height: 150px;
    top: 150px;
    border-top: solid 2px #fff;
    border-left: solid 2px #fff;
  }
}

.wrapper4 {
  :global(.img0) {
    left: 0;
    top: 0;
    height: 150px;
    border-right: solid 2px #fff;
    border-bottom: solid 2px #fff;
  }
  :global(.img1) {
    left: 50%;
    top: 0;
    height: 150px;
    border-left: solid 2px #fff;
    border-bottom: solid 2px #fff;
  }
  :global(.img2) {
    left: 50%;
    height: 150px;
    top: 150px;
    border-left: solid 2px #fff;
    border-top: solid 2px #fff;
  }
  :global(.img3) {
    left: 0;
    height: 150px;
    top: 150px;
    border-right: solid 2px #fff;
    border-top: solid 2px #fff;
  }
}

.modal {
  position: relative;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: top;
  align-items: top;
  overflow: hidden;
}

.modalContent {
  width: 90%;
  height: 90%;
  max-width: 900px;
  max-height: 800px;
  background-color: white;
  position: relative;
  display: flex;
  justify-content: top;
  align-items: top;
  overflow: hidden;
}
