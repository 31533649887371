@import '../../common.module.scss';

.avatarContainer {
  width: 50px;
  display: inline-block;
  vertical-align: top;
}
.avatar {
  height: 45px;
  width: 45px;
  border-radius: 50%;
}
.avatarButton {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.avatarButton:focus {
  outline: none;
}