.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.loadingSpinner {
  margin: 10px;
}
  