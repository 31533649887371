@import '../../common.module.scss';

.container {
  height: 200px;
  padding: 10px 5px 0 0;
  margin-bottom: 10px;
}
.img {
  position: absolute;
  width: 50%;
  object-fit: fill;
  background-color: aqua;
  border-radius: 20px;
  box-sizing: border-box;
  overflow: hidden;
}

.itemImg {
  height: 100%;
  width: 100%;
}

.wrapper {
  position: relative;
  overflow: hidden;
  height: 200px;
  width: 100%;
}


.wrapper1 {
  :global(.img0) {
    left: 0;
    width: 100%;
    height: 200px;
  }
}

.wrapper2 {
  :global(.img0) {
    left: 0;
    width: 50%;
    height: 200px;
    border-right: solid 5px #fff;
  }
  :global(.img1) {
    border-left: solid 5px #fff;
    left: 50%;
    width: 50%;
    height: 200px;
  }
}

.wrapper3 {
  :global(.img0) {
    left: 0;
    height: 200px;
    border-right: solid 5px #fff;
  }
  :global(.img1) {
    left: 50%;
    height: 100px;
    border-left: solid 5px #fff;
    border-bottom: solid 5px #fff;
  }
  :global(.img2) {
    left: 50%;
    height: 100px;
    top: 100px;
    border-top: solid 5px #fff;
    border-left: solid 5px #fff;
  }
}

.wrapper4 {
  :global(.img0) {
    left: 0;
    top: 0;
    height: 100px;
    border-right: solid 5px #fff;
    border-bottom: solid 5px #fff;
  }
  :global(.img1) {
    left: 50%;
    top: 0;
    height: 100px;
    border-left: solid 5px #fff;
    border-bottom: solid 5px #fff;
  }
  :global(.img2) {
    left: 50%;
    height: 100px;
    top: 100px;
    border-left: solid 5px #fff;
    border-top: solid 5px #fff;
  }
  :global(.img3) {
    left: 0;
    height: 100px;
    top: 100px;
    border-right: solid 5px #fff;
    border-top: solid 5px #fff;
  }
}
.close {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  color: #fff;
  background-color: rgba(0,0,0,0.5);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  font-size: 12px;
  padding: 5px;
}