@import '../../common.module.scss';

.wrap {
  composes: wrap from '../../common.module.scss';

}
.login {
  composes: login from '../../common.module.scss';

}

.left {
  composes: left from '../../common.module.scss';

}

.left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.right {
  composes: right from '../../common.module.scss';

}
.input {
  composes: input from '../../common.module.scss';
}
.formTitle {
  composes: formTitle from '../Register/index.module.scss';
}
.form {
  composes: form from '../Register/index.module.scss';
}
.footerButton {
  composes: footerButton from '../Register/index.module.scss';
  background-color: #000;
}
.goToRegister {
  width: 100px;
  margin-right: 250px;
  font-size: 14px;
}
.Link {
  margin-left: 5px;
}
