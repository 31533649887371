@import "../../common.module.scss";

.Header {
  font-size: 20px;
  font-weight: bold;
}
.container {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.leftSider {
  align-items: center;
  width: 360px;
  position: fixed; // Add this property
  left: 40px; // Add this property
  right: 40px;
  top: 20px; // Add this property
  height: calc(100% - 20px); // Add this property
  overflow-y: hidden; // Add this property
  flex: 0 0 300px;
  padding: 20px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mainContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 600px;
  padding: 30px;
  margin-left: 300px;
  margin-right: 300px;
  border: 1px solid #e6e6e6;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 600px;
}

.rightSider {
  width: 360px;
  position: fixed; // Add this property
  right: 40px; // Add this property
  top: 20px; // Add this property
  height: calc(100% - 20px); // Add this property
  overflow-y: auto; // Add this property
  flex: 0 0 350px;
  padding: 20px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.searchContainer {
  width: 600px;
  display: flex;
  margin-top: 25px;
  margin-bottom: 1rem;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  .searchInput {
    border: solid 1px $tGrayWhite;
    border-radius: 25px;
    height: 43px;
    padding-left: 30px;
    box-sizing: border-box;
    width: 350px;
  }

  .closeIcon {
    position: absolute;
    left: calc(50% - 375px);
    font-size: 22px;
  }
}
