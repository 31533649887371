@import url("https://fonts.googleapis.com/css2?family=Monoton&family=Quicksand:wght@500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 2.4rem;
  font-family: sans-serif;
  color: #5a3e2b;
  font-family: "Quicksand";
  font-weight: 500;
}

.app {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
}

h1 {
  text-align: center;
  background-color: #f4a226;
  font-family: "Monoton";
  font-size: 8rem;
  text-transform: uppercase;
  font-weight: 400;
  word-spacing: 30px;
  letter-spacing: -5px;
  padding: 2.4rem 0;
}

.add-form {
  background-color: white;
  padding: 7.8rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}

h3 {
  margin-right: 1.6rem;
  font-size: 2.4rem;
}

.add-form button,
select,
input {
  background-color: #ffebb3;
  color: #5a3e2b;
  font-family: inherit;
  border: none;
  border-radius: 10rem;
  padding: 1.2rem 3.2rem;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
}

.add-form button {
  text-transform: uppercase;
  background-color: #76c7ad;
}

.strategy-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.strategy-table th,
.strategy-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.strategy-table th {
  background-color: #f2f2f2;
}

/* Optional: Style for striped rows */
.strategy-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.filters-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.column-filters {
  display: flex;
}

.column-filters input {
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
}

.clear-filters-container {
  display: flex;
  justify-content: flex-end;
}

.clear-filters-container button {
  text-transform: uppercase;
  background-color: #76c7ad;
  padding: 1.2rem 3.2rem;
  border: none;
  border-radius: 10rem;
  color: #5a3e2b;
  font-family: inherit;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
}
