@import '../../common.module.scss';


.navContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between; // Add this property
  padding-left: 0;
}

.navList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navListItem {
  cursor: pointer;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: bold;
  color: #47525e;
  transition: all 0.3s;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  outline: none;
  appearance: none;
  text-align: left;
  border-radius: 4px;
}

.navListItem:hover {
  background-color: #e6f7ff;
  color: #464e55;
  transform: scale(1.05);
}

.active {
  color: #030303;
}

.post {
  margin: 10 auto 10;
  color: #1e10b3;
  background-color: transparent;
  border: none;
  outline: none;
  appearance: none;
  cursor: pointer;
  padding: 16px 24px;
  font-size: 18px;
  font-weight: bold;
  transition: all 0.3s;
  text-align: left;
  border-radius: 4px;
}

.post:hover {
  color: #0576b9;
  background-color: #e6f7ff;
}

.logoutWrapper {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  color: $tBlackColor;
  font-size: 16px;
  font-weight: 700;
  height: 50px;
  border-top: 1px solid $tBorderColor;
  line-height: 50px;
  padding-left: 15px;
}

.logout {
  background-color: transparent;
  border: none;
  outline: none;
  appearance: none;
  cursor: pointer;
  padding: 16px 24px;
  font-size: 18px;
  font-weight: bold;
  transition: all 0.3s;
  text-align: left;
  border-radius: 4px;
}
