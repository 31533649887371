.GPTPage {
  width: 100%;  // Use 100% width to be more responsive
  max-width: 1960px;  // Increasing max width for better utilization of screen space
  margin: 20px auto;  // Center the page appropriately
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);  // Optional: adds a subtle shadow for depth
}

.ant-tabs-nav-wrap {
  padding: 0 20px;  // Add padding to the tab nav wrapper for better alignment
}

.ant-tabs-tab {
  font-size: 16px;  // Larger font size for better readability
  margin-right: 24px;  // Increase space between tabs
}

.ant-tabs-tab.ant-tabs-tab-active {
  font-weight: bold;
  color: #1890ff;  // Highlight color for active tab
}

.ant-tabs-ink-bar {
  background-color: #1890ff;  // Active tab underline color
}
