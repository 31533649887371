.trading-page {
  width: 100%;
  height: 100%;
  max-width: 2000px;
  margin: 0 auto;
  padding: 20px;
}

.trading-page h1 {
  text-align: center;
  color: #333;
}

.add-form {
  padding: 2.8rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}

.add-form button {
  text-transform: uppercase;
  background-color: #76c7ad;
}

.add-form Select {
  background-color: #ffebb3;
  color: #5a3e2b;
  font-family: inherit;
  border: none;
  border-radius: 10rem;
  padding: 1.2rem 3.2rem;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
}

.add-form input {
  max-width: 120px;
}

.add-form .dateinput {
  max-width: 220px;
}
.records-container {
  max-width: 2000px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.record-status.active {
  color: #007bff;
}

.record-status.sold {
  color: #28a745;
}

.sell-reason-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-sizing: border-box;
}

.sell-button {
  width: 100%;
  background-color: red;
  font-family: inherit;
  border: none;
  padding: 1rem 2rem;
  font-weight: 800;
  font-size: 1.1rem;
  cursor: pointer;
}

.button {
  width: 100%;
  background-color: #ffebb3;
  color: #5a3e2b;
  font-family: inherit;
  border: none;
  padding: 1rem 2rem;
  font-weight: 800;
  font-size: 1.1rem;
  cursor: pointer;
}

.reactive-button {
  width: 100%;
  background-color: #28a745;
  font-family: inherit;
  border: none;
  padding: 1rem 2rem;
  font-weight: 300;
  font-size: 1.1rem;
  cursor: pointer;
}

.reactive-button:hover {
  background-color: #218838;
}

.filter-container {
  margin-bottom: 20px;
}
