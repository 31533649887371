
$tBlue: #1d9bf0;
$tGray: #868a8c;
$tGrayWhite: #cfd9de;
$tFontColor: #536471;
$tBorderColor: #eee;
$tBlackColor: #0f1419;
.input {
  border: solid 1px $tGrayWhite;
  border-radius: 5px;
  height: 53px;
  padding: 0 5px;
  box-sizing: border-box;
  width: 350px;
}
.icon{
  margin-left: 45px;
  height: 40px;
}

.wrap {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
}
.login {
  width: 900px;
  height: 550px;
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
}

.left {
  height: 560px;
  width: 500px;
}

.right {
  width: 400px;
  height: 560px;
  background: rgb(255, 255, 255, 9);
  display: flex;
  align-items: center;
  justify-content: center;
}