.container {
  // position: 'relative';
  // margin-left: 400px;
  margin-top: 10px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  padding: 10px 10px 0 10px;
}
.avatarContainer {
  width: 50px;
  display: inline-block;
  vertical-align: top;
}
.avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.contentContainer {
  width: calc(100% - 50px);
  display: inline-block;
  padding-left: 5px;
  box-sizing: border-box;
  .header {
    height: 20px;
    .nickname {
      font-weight: bold;
      margin-right: 5px;
    }
  }
}