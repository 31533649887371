.container {
  // position: 'relative';
  // margin-left: 400px;
  margin-top: 10px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  padding: 10px 10px 0 10px;
  width: 100%;

}
.avatarContainer {
  width: 50px;
  display: inline-block;
  vertical-align: top;
}
.avatar {
  height: 40px;
  border-radius: 50%;
  width: 80%;
}
.contentContainer {
  width: calc(100% - 50px);
  display: inline-block;
  padding-left: 5px;
  box-sizing: border-box;
  .header {
    height: 20px;
    .nickname {
      font-weight: bold;
      margin-right: 5px;
    }
  }
}
.recommit {
  margin-top: 10px;
  color: #536471;
  font-size: 14px;
  font-weight: 400;
  .commitName {
    margin-left: 5px;
    color: #1d9bf0;
  }
}
.text {
  width: 200px;
}
.reply {
  width: 100%;
}

.replyContainer {
  margin-top: 50px;
}