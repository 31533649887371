@import "../../common.module.scss";

.wrap {
  composes: wrap from "../../common.module.scss";
}
.login {
  composes: login from "../../common.module.scss";
}

.left {
  composes: left from "../../common.module.scss";
}

.left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.right {
  composes: right from "../../common.module.scss";
}

.input {
  composes: input from "../../common.module.scss";
}
.datePicker {
  composes: input from "../../common.module.scss";
}
.formTitle {
  font-size: 25px;
  font-weight: 700;
  height: 58px;
  line-height: 58px;
}
.form {
  margin: 30px 30px;
  text-align: center;
}
.changeTypeButton {
  color: $tBlue;
  text-align: center;
}
.birthTitle {
  text-align: center;
  font-size: 20px;
  color: #000;
  font-weight: 700;
}
.footer {
  margin-bottom: 5px;
  text-align: center;
}
.footerButtonDisabled {
  margin: 0 auto 30px;
  border-radius: 50px;
  background-color: $tGray;
  color: #fff;
  font-size: 16px;
  width: 40%;
  height: 40px;
}
.footerButton {
  composes: footerButtonDisabled;
  background-color: #000;
}
.showLabelContainer {
  border: solid 1px $tGrayWhite;
  padding: 10px;
  border-radius: 5px;
}
.showLabel {
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  :global(span) {
    color: $tGray;
  }
}
.label {
  font-size: 18px;
  margin: 30px 0 10px 0;
}
.showTip {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
.icon {
  margin-left: 45px;
  height: 40px;
}

.spanLabel {
  align-items: center;
}
