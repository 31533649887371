@import '../../common.module.scss';

.container {
  width: 100%;
  left: 0;
}
.icon {
  height: 20px;
  vertical-align: middle;
}
.count {
  font-size:12px;
  color: $tGray;
  margin-left: 5px;
}