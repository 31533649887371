@import '../../common.module.scss';

.upFile {
  opacity: 0;
  position: absolute;
  z-index: 1;
  height: 20px;
  width: 20px;
}
.imageUpload {
  fill: $tBlue;
}
.imageButton {
  height: 20px;
  width: 20px;
}