@import '../../common.module.scss';

.container {
  background-color: #fff;
}
.contentContainer {
  max-width: 638px;
  margin-top: 40px;
  margin-left: 30%;
  margin-right: 30%;
  padding: 20px;
  .header {
    height: 60px;
    font-size: 14px;
    display: flex; // Add display: flex;
    align-items: center; // Add align-items: center;
  
    .avatar {
      height: 50px;
      width: 50px;
      border-radius: 50%;
    }
  
    .rightPart {
      height: 60px;
      margin: 35px 15px 15px 15px;
      display: flex;
      width: calc(100% - 60px);
      align-items: left;
      justify-content: left;
      .nickname {
        font-weight: bold;
      }
      .username {
        color: $tFontColor;
        margin-left: 5px;
      }
    }    
  }
  .content {
    font-size: 22px;
  }
}

.text {
  width: calc(100% - 140px);
  float: right;
  margin-top: 10px;
  :global(.adm-text-area-element) {
    font-size: 19px;
    font-weight: 400;
  }
}
.photo {
  padding: 10px 0 0 0;
}
.time {
  font-size: 14px;
  color: $tFontColor;
  margin: 25px auto;
}
.bar {
  border-top: 1px solid $tBorderColor;
  border-bottom: 1px solid $tBorderColor;
}